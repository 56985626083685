<template>
  <TepmplateBlock
    content-class="main-wrap pt-4 m-10"
    mod-class
    title-page="Профиль"
  >
    <ul class="row">
      <li
        class="main-list__item p-3"
        :class="{
          colored:
            item.link && needHighlight && item.link.indexOf('profile') > -1,
          'over-top':
            isIntroPassed === 'false' &&
            item.link &&
            item.link.indexOf('profile') > -1
        }"
        v-for="item in items"
        :key="item.message"
      >
        <router-link class="main-list__link" :to="item.link">
          <div class="main-list__icon-wrap mr-3">
            <img class="main-list__icon" :src="item.src" alt="" />
          </div>
          {{ item.message }}
          <help-popover v-if="item.help" :message="item.help" class="ml-1" />
        </router-link>
      </li>
    </ul>

    <div v-if="isIntroPassed === 'false'" class="dark-intro-background full">
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <div class="intro-tip p-2 w-25 text-center">
          Перейдите в раздел "Управление компанией"
        </div>
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import HelpPopover from "../../components/HelpPopover";
import { mapActions, mapGetters } from "vuex";
import { GET_MAIN_COMPANY_INFO, GET_BANK_INFO } from "../../store/types";
import { doPoseTip } from "@/utils";

export default {
  name: "MyProfile",
  components: { TepmplateBlock, HelpPopover },
  computed: {
    ...mapGetters([
      "currentUserV2",
      "bankInfo",
      "mainCompanyInfo",
      "adminProfile"
    ]),
    needHighlight() {
      return (
        !this.mainCompanyInfo.ogrn ||
        !this.adminProfile.website ||
        !this.bankInfo?.bank_bik
      );
    }
  },
  data() {
    return {
      items: [
        {
          message: "Управление компанией",
          link: "/admin-profile",
          src: require("../../resource/img/admin.svg"),
          help:
            "В данном разделе содержится информация о компании, также здесь вы можете добавить новые офисы и новых " +
            "сотрудников. !!! Если вы только что зарегистрировались, то для начала работы, вам необходимо заполнить данные в разделе 'Управление компанией'."
        }
        // {
        //   message: "Список пользователей",
        //   link: "/user-list",
        //   src: require("../../resource/img/man.svg")
        // }
        //   Страница список пользователей
      ]
    };
  },
  mounted() {
    this.GET_MAIN_COMPANY_INFO();
    this.GET_BANK_INFO();
    setTimeout(() => {
      doPoseTip(".over-top", "bottom");
    }, 100);
  },
  methods: {
    ...mapActions({ GET_MAIN_COMPANY_INFO, GET_BANK_INFO })
  },
  created() {
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
  }
};
</script>

<style lang="scss" scoped>
.main-list__item {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 15px;
  margin-bottom: 14px;
  width: 342px;
  height: 96px;

  list-style-type: none;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
.main-list__item.colored {
  background-color: #d1ecf1;
}

.main-list__link {
  display: flex;
  align-items: center;
  padding: 17px 20px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;

  color: var(--card-text-color);
}

@media (min-width: 320px) {
  .main-list__link {
    padding: 0;
  }
}
@media (min-width: 375px) {
  .main-list__link {
    padding: 0;
  }
}
@media (min-width: 425px) {
  .main-list__link {
    padding: 0;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  .main-list__item {
    padding: 0;
    margin: 1;
    top: 10px;
    margin-left: -50px;
    border-radius: 6px;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .row {
    margin-right: 10px;
    margin-left: 0;
  }
  .main-list__item {
    margin-left: -50px;
    border-radius: 6px;
  }
}
@media (min-width: 425px) and (max-width: 514px) {
  .row {
    margin-right: 10px;
    margin-left: 0;
  }
  .main-list__item {
    margin-left: -50px;
    border-radius: 6px;
  }
}
.main-list__icon-wrap {
  position: relative;
  width: 62px;
  height: 62px;

  background: rgba(81, 162, 190, 0.15);
  border-radius: 50%;
}

.main-list__icon {
  position: absolute;
  top: 20px;
  left: 20px;
}
</style>
