var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TepmplateBlock',{attrs:{"content-class":"main-wrap pt-4 m-10","mod-class":"","title-page":"Профиль"}},[_c('ul',{staticClass:"row"},_vm._l((_vm.items),function(item){return _c('li',{key:item.message,staticClass:"main-list__item p-3",class:{
        colored:
          item.link && _vm.needHighlight && item.link.indexOf('profile') > -1,
        'over-top':
          _vm.isIntroPassed === 'false' &&
          item.link &&
          item.link.indexOf('profile') > -1
      }},[_c('router-link',{staticClass:"main-list__link",attrs:{"to":item.link}},[_c('div',{staticClass:"main-list__icon-wrap mr-3"},[_c('img',{staticClass:"main-list__icon",attrs:{"src":item.src,"alt":""}})]),_vm._v(" "+_vm._s(item.message)+" "),(item.help)?_c('help-popover',{staticClass:"ml-1",attrs:{"message":item.help}}):_vm._e()],1)],1)}),0),(_vm.isIntroPassed === 'false')?_c('div',{staticClass:"dark-intro-background full"},[_c('div',{staticClass:"intro-tip-wrapper"},[_c('div',{staticClass:"intro-tip p-2 w-25 text-center"},[_vm._v(" Перейдите в раздел \"Управление компанией\" ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }